import React, { useRef, useState, useEffect } from 'react'
import { toggleGalleryOverlay } from '../../actions'
import { connect } from 'react-redux'
import styles from './ParallaxTeaser.module.scss'
import { Motion, spring } from 'react-motion/lib/react-motion'
import * as MathUtil from '../../utils/MathUtil'
import { usePrevious } from '../../hooks/usePreviousValue'
import * as MediaQuery from '../../constants/MediaQuery'

const ParallaxTeaser = ({
							windowSize, scrollY, title, link, slides, toggleGalleryOverlay,
						}) => {
	const parallaxRef = useRef(null)
	const prevWinSize = usePrevious(windowSize)
	// transparent pixel gif
	const [imgSrc, setImgSrc] = useState('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')

	const getImagPos = (yPos, maxVh = 30, reverse = false) => {
		// center if not defined
		if (!parallaxRef.current) return maxVh / 2

		const wrapperRect = parallaxRef.current.getBoundingClientRect()
		const elPos = wrapperRect.top + yPos
		const posMin = elPos - windowSize.height
		const posMax = elPos + wrapperRect.height
		const imagePos = MathUtil.map(yPos, posMin, posMax, 0, maxVh)

		return `-${ reverse ? maxVh - imagePos : imagePos }vh`
	}

	const onShowOverlay = () => {
		toggleGalleryOverlay(slides)
	}

	useEffect(() => {
		if (windowSize && windowSize.ratio) {
			if ((!prevWinSize || (prevWinSize.ratio !== windowSize.ratio)) && (slides && slides.length > 0)) {
				// update source
				let targetImageSize
				switch (true) {
					case windowSize.width >= MediaQuery.LG:
						targetImageSize = 'l'
						break

					case windowSize.width >= MediaQuery.MD:
						targetImageSize = 'm'
						break

					default:
						targetImageSize = 's'
				}

				console.log('update image source to: ', targetImageSize)
				setImgSrc(slides[0].image[targetImageSize])
			}
		}

	}, [windowSize, prevWinSize, slides])

	return (
		<Motion defaultStyle={ { scrollY: 0 } }
				style={ { scrollY: spring(scrollY, { stiffness: 170, damping: 5 }) } }>
			{ interpolatingStyle => {
				return (
					<div ref={ parallaxRef } className={ styles.parallax }>
						<div className={ `${ styles.imageWrapper }` }
							 style={ { transform: `translate3d(0, ${ getImagPos(interpolatingStyle.scrollY, 40, true) }, 0)` } }
						>
							{ slides && slides.length > 0 && <img className={ styles.teaserImage }
																  src={ imgSrc }
																  alt={ slides[0].alt }/>
							}
						</div>
						<div className={ styles.contentWrapper }
							 style={ { transform: `translate3d(0, ${ getImagPos(interpolatingStyle.scrollY, 100) }, 0)` } }
						>
							<div className={ 'container' }>
								<div className={ 'row' }>
									<div className={ `col col-12 ${ styles.contentColumn }` }>
										{ title && <h1 className={ styles.contentTitle }>{ title }</h1> }
										{ link && slides && slides.length > 0 &&
										<p className={ styles.contentLink } onClick={ onShowOverlay }>{ link } →</p>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			} }
		</Motion>
	)
}

export default connect(null, {
	toggleGalleryOverlay,
})(ParallaxTeaser)