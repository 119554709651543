import React from 'react';
import styles from './HomePage.module.scss';
import MobileDetect from "mobile-detect";
import * as Stats from 'three/examples/js/libs/stats.min';
import CSS3DView from '../components/Scene3d/CSS3DView';
import CameraControl from '../components/Scene3d/CameraControl';
import WebGLView from '../components/Scene3d/WebGLView';
import * as MathUtil from '../utils/MathUtil';
import TimelineMax from 'gsap/TimelineMax';
import * as Ease from 'gsap/EasePack';
import {Link} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as MediaQuery from '../constants/MediaQuery';
import {setScrollbarWidth} from '../actions';

class HomePage extends React.Component {
	constructor(props) {
		console.log("constructor home");
		super(props);

		this.md = new MobileDetect(window.navigator.userAgent);
		this.isMobile = this.md.phone() || this.md.tablet();
		this.THREE = window.THREE;
		this.cameraGroup = new this.THREE.Group();
		this.requestID = null;
		this.groupRotation = new this.THREE.Vector3(0,0,0);
		this.mouse = new this.THREE.Vector2(0,0);
		this.touchStart = new this.THREE.Vector2(0,0);

		this.state = {
			setupComplete: false,
			camera: null,
			glScene: null,
			cssScene: null,
			glRenderer: null,
			cssRenderer: null,
			introComplete: false,
			deviceOrientationControls: null,
			pause: false
		};

		this.containerRef = React.createRef();
		this.mouseMoveHandler = this.onDocumentMouseMove.bind(this);
		this.touchMoveHandler = this.onTouchMove.bind(this);
		this.touchStartHandler = this.onTouchStart.bind(this);
	}

	componentWillUnmount() {
		window.cancelAnimationFrame(this.requestID);
		document.removeEventListener('mousemove', this.mouseMoveHandler);
		document.removeEventListener('touchmove', this.touchMoveHandler);
		document.removeEventListener('touchstart', this.touchStartHandler);
	}

	componentDidMount() {
		this.setup3D();
		this.animate();

		document.addEventListener('mousemove', this.mouseMoveHandler);
		document.addEventListener('touchmove', this.touchMoveHandler);
		document.addEventListener('touchstart', this.touchStartHandler);
	}

	componentDidUpdate(prevProps, prevState) {
		const {windowSize, selectedId, id, setScrollbarWidth} = this.props;
		const prevWinSize = prevProps.windowSize;

		// WINDOW SIZE CHANGE
		if(prevWinSize && windowSize) {
			if(prevWinSize.ratio !== windowSize.ratio) {
				this.onWindowResize();
			}
		}

		// check if active section is home
		if(id !== selectedId && !this.state.pause) {
			this.setState({pause: true});
		}else if(id === selectedId && this.state.pause) {
			setScrollbarWidth(0);
			this.setState({pause: false});
		}

		// initial resize after setup complete
		if(!prevState.setupComplete && this.state.setupComplete) {
			this.onWindowResize();
		}
	}

	setup3D() {
		const {cameraSetup, windowSize} = this.props;

		// create camera
		const camStartZPos = windowSize.ratio < 1 ? cameraSetup.startZPortrait : cameraSetup.startZ;
		const camera = new this.THREE.PerspectiveCamera(cameraSetup.defaultFOV, windowSize.width / windowSize.height, 1, cameraSetup.maxFar);
		const cameraStartPos = new this.THREE.Vector3(0, 0, camStartZPos);

		camera.position.copy(cameraStartPos);
		this.cameraGroup.add(camera);

		// create scenes
		const glScene = new this.THREE.Scene();
		const cssScene = new this.THREE.Scene();
		// glScene.fog = new THREE.Fog( '#ff0000', -1500, camera.far * 0.75);
		cssScene.add(this.cameraGroup);

		// create renderer
		const cssRenderer = new this.THREE.CSS3DRenderer();
		cssRenderer.setSize(windowSize.width, windowSize.height);

		const glRenderer = new this.THREE.WebGLRenderer({antialias: true, alpha: true});
		glRenderer.setPixelRatio(window.devicePixelRatio);
		glRenderer.setSize(windowSize.width, windowSize.height);
		// glRenderer.gammaInput = true;
		// glRenderer.gammaOutput = true;

		if(this.props.showStats) {
			this.stats = new Stats();
			this.containerRef.current.appendChild( this.stats.dom );
		}

		this.setState(
			{
				setupComplete: true,
				camera,
				glScene,
				cssScene,
				glRenderer,
				cssRenderer
			}
		);
	}

	startIntro(delay) {
		const {camera} = this.state;
		const {cameraSetup, introDuration} = this.props;
		const tl = new TimelineMax(
			{
				delay,
				onComplete: () => {
					this.setState({introComplete: true})
				}
			});

		tl.addLabel('start');
		tl.fromTo(this.cameraGroup.rotation, introDuration * 0.9,
							{x: this.THREE.Math.degToRad(20), y: this.THREE.Math.degToRad(30)},
							{x: 0, y: 0, ease: Ease.Sine.easeOut},
							'start'
		);
		tl.fromTo(camera.position, introDuration,
							{z: cameraSetup.startZ + 1200},
							{z: cameraSetup.startZ, ease: Ease.Sine.easeOut},
							'start'
		);
	}

	onTouchStart(event) {
		if(event.touches.length === 1) {
			this.touchStart = new this.THREE.Vector2(event.touches[0].clientX, event.touches[0].clientY);
		}
	}

	onTouchMove(event) {
		const {pause} = this.props;

		if (!pause && (event.touches.length === 1)) {
			event.preventDefault();
			this.onDocumentMouseMove(
				{
					clientX: event.touches[0].clientX,
					clientY: this.touchStart.y //event.touches[0].clientY
				},
				0.5,
				true
			);
		}
	}

	onDocumentMouseMove(event, limitter = 1, reverse = false) {
		const {windowSize} = this.props;
		const {setupComplete} = this.state;

		if(setupComplete) {
			const windowHalfX = reverse ? this.touchStart.x : windowSize.width / 2;
			const windowHalfY = reverse ? this.touchStart.y : windowSize.height / 2;

			// if(reverse) limitter *= -1;

			this.mouse = new this.THREE.Vector2(
				(event.clientX - windowHalfX) / limitter,
				(event.clientY - windowHalfY) / limitter
			);
		}
	}

	onWindowResize() {
		if (this.state.setupComplete) {
			const {windowSize} = this.props;
			const {camera, glRenderer, grainRenderer, cssRenderer} = this.state;

			// update fov to fit Object
			let targetWidth = 0;

			switch(true) {
				case windowSize.width >= MediaQuery.XL:
					targetWidth = Math.max(windowSize.width - 800, MediaQuery.XL);
					break;

				case windowSize.width >= MediaQuery.LG:
					targetWidth = windowSize.width;
					break;

				case windowSize.width >= MediaQuery.MD:
				case windowSize.width >= MediaQuery.SM:
					targetWidth = 850;
					break;

				default:
					targetWidth = 380;
			}

			camera.fov = MathUtil.getFOVToFitObject(windowSize.ratio, camera.position.z, targetWidth);
			camera.aspect = windowSize.ratio;
			camera.updateProjectionMatrix();

			if(glRenderer) glRenderer.setSize(windowSize.width, windowSize.height);
			if(cssRenderer) cssRenderer.setSize(windowSize.width, windowSize.height);

			if(grainRenderer) grainRenderer.setSize(windowSize.width, windowSize.height);

			this.containerRef.current.style.height = `${windowSize.height}px`;
		}
	}

	moveScene() {
		const {friction, windowSize} = this.props;
		const {introComplete} = this.state;

		// mouse controls
		const mapMouseX = MathUtil.map(this.mouse.x, -windowSize.width / 2, windowSize.width / 2, -8, 8);
		const mapMouseY = MathUtil.map(this.mouse.y, -windowSize.height / 2, windowSize.height / 2, -8, 8);

		if(introComplete) {
			this.cameraGroup.rotation.set(
				this.groupRotation.y += (this.THREE.Math.degToRad(mapMouseY) - this.groupRotation.y) * friction,
				this.groupRotation.x += (this.THREE.Math.degToRad(mapMouseX) - this.groupRotation.x) * friction,
				0
			);
		}
	}

	animate() {
		window.requestAnimationFrame(() => {
			this.animate()
		});

		if (this.state.setupComplete && !this.state.pause) {
			const {camera, glRenderer, grainRenderer, glScene, cssScene, cssRenderer} = this.state;

			if(glRenderer) glRenderer.render(glScene, camera);
			if(cssRenderer) cssRenderer.render(cssScene, camera);
			if(grainRenderer) grainRenderer.render(glScene, camera);
		}

		if(this.props.showStats) {
			this.stats.update();
		}

		this.moveScene();
	}

	render() {
		const {cameraSetup, windowSize, introDuration, content} = this.props;
		const {glScene, glRenderer, cssScene, cssRenderer, camera, pause} = this.state;

		if(!content) return null;

		return (
			<React.Fragment>
				<div className={styles.container} ref={this.containerRef}>
					<WebGLView
						scene={glScene}
						renderer={glRenderer}
					/>

					<CSS3DView
						scene={cssScene}
						camera={camera}
						renderer={cssRenderer}
						windowSize={windowSize}
						onStartIntro={this.startIntro.bind(this)}
						introDuration={introDuration}
						content={content}
						pause={pause}
					/>

					<CameraControl
						camera={camera}
						cssScene={cssScene}
						windowSize={windowSize}
						cameraSetup={cameraSetup}
						pause={pause}
					/>
				</div>
				<div className={`${styles.buttonContainer} container-fluid d-none d-md-block`}>
					<div className="container">
						<div className="row">
							<div className="col col-12 col-md-6 text-right">
								<Link className="button" to={content.linkDevelopment.path}
											dangerouslySetInnerHTML={{__html: content.linkDevelopment.label}} />
							</div>
							<div className="col col-12 col-md-6">
								<Link className="button" to={content.linkIllustration.path}
											dangerouslySetInnerHTML={{__html: content.linkIllustration.label}} />
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

HomePage.defaultProps = {
	enableGrain: true,
	cameraSetup: {
		startZ: 770,
		startZPortrait: 700,
		minZ: 600,
		maxFar: 10000,
		defaultFOV: 50,
	},
	friction: 0.02,
	showStats: false,
	introDuration: 3
};

const mapStateToProps = (state) => {
	return {
		sitemap: state.sitemap
	}
};

export default connect(mapStateToProps, {
	setScrollbarWidth
})(HomePage);